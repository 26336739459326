<template>
  <router-view></router-view>
</template>

<script setup>
  import { onMounted,provide } from 'vue';
  import * as api from "./request/api";
  import {useMainStore} from "./store/index";
  provide("app",{
    api,
    useMainStore
  })
  //页面字体
  let initScale=()=>{
    var scale = 750/100;
    var doc = window.document;
    var docEl = doc.documentElement;
    var clientWidth = docEl.clientWidth||window.innerWidth;
    /* 页面宽度大于 设计图文档宽度 时不再放大,小于320时不再缩小 */
    if(clientWidth>750){
      clientWidth=750;
    }
    if(clientWidth<320){
      clientWidth=320;
    }
    var oldSize = (clientWidth/scale*2) + 'px';
    docEl.style.fontSize = oldSize;
  }
  //设置高度
  const setHeight=()=>{
    let odiv = document.getElementById('app');
    odiv.style.height=window.innerHeight+'px';
  }
  //获取渠道参数
  const getParams=()=>{
    let hash = location.hash;
    localStorage.setItem('s','');
    localStorage.setItem('u','');
    localStorage.setItem('m','');
    if(hash){
      let list = hash.split('?');
      let arr = list[list.length-1].split('&');
      for(let i=0;i<arr.length;i++){
        let str = arr[i];
        let arr1=str.split('=');
        if(arr1[0]=='s'){
          localStorage.setItem('s',arr1[1]);
        }
        if(arr1[0]=='u'){
          localStorage.setItem('u',arr1[1]);
        }
        if(arr1[0]=='m'){
          localStorage.setItem('m',arr1[1]);
        }
      }
    }
  }
  //适配跳转
  const fitUrl=()=>{
    let hash = window.location.hash;
    if(hash.indexOf('wsava')>-1){
      if(hash.indexOf('l=zh')>-1){
       window.location.href='https://farminagenius.com.cn/wsava/#/zh/login';
      }
      if(hash.indexOf('l=en')>-1){
        window.location.href='https://farminagenius.com.cn/wsava/#/en/login';
      }
    }
  }
  onMounted(()=>{
    fitUrl();
    getParams();
    setHeight();
    initScale();
    window.addEventListener('resize',()=>{
      initScale();
      setHeight();
    })
  })
</script>

<style lang="scss">
</style>
