import { defineStore } from 'pinia';
 
export const useMainStore = defineStore('main',{
  state: () => {
    return {
      userCode: "",//用户编号
      isAgree:false,//是否同意隐私条款
      s:'',//渠道
      u:'',//
    }
  },
  actions: {
  },
});