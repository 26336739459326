
import { showLoadingToast,closeToast,showToast } from 'vant';
import axios from 'axios';
import qs from "qs";
let base_url=location.origin+'/';
if (process.env.NODE_ENV === 'development') { //开发环境
  base_url='https://farmina-pre.maytek.cn/';
  // base_url='http://192.168.50.101:8898';
} else if (process.env.NODE_ENV === 'production') { //生产环境
  base_url= location.origin+'/';
}
axios.defaults.timeout = 10000000;
axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.interceptors.request.use(config => {
  if (config.headType == 'json') {
    config.headers['Content-Type'] = 'application/json';
  } else if (config.headType) {
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
  } else {
    delete config.headers['Content-Type'];
  }
  config.data.s=localStorage.getItem('s')||'';
  config.data.u=localStorage.getItem('u')||'';
  config.data.m=localStorage.getItem('m')||'';
  let token = localStorage.getItem('token');
  if(token){
    config.headers['Authorization']=`Bearer ${token}`;
  }
  let userCode =localStorage.getItem('userCode');
  if(userCode){
    config.data.userCode=userCode;
  }
  if(config.method.toLowerCase() === 'post') {
    if(!config.url.includes('http')){
      config.url = base_url  + config.url;
    }
    if (!config.noJson) {
      config.data = config.headType == "json" ? config.data : qs.stringify(config.data);
    }
  }
  if (config.method.toLowerCase() === 'get') {
    const data = qs.stringify(config.data);
    let url = base_url  + config.url;
    config.url = url + `?${data}`;
  }
  return config;
}, error => {
  return Promise.reject(error)
})
export default function request(option) {
  if(!option.data) {
    option.data = {};
  }
  if(option.load){
    showLoadingToast({
      duration: 0,
      forbidClick: true,
      message: '加载中...',
    })
  }
  return axios(option).then(res => res.data).then(res => {
    res.status = res.code == 200? 1 : 0;
    if(option.load){
      setTimeout(()=>{
        closeToast();
      },500)
    }
    if(res.status==0 && !option.hideMsg){
      showToast({
        message:res.msg,
        type:"fail",
        duration:2000
      })
    }
    if(res.code==401){
      localStorage.removeItem('userCode');
      location.href=location.origin+'#/index';
    }
    return res;
  }).catch(res => {
    const response = res.response;
    const statusText = response && response.statusText || '';
    if (statusText) {
      showToast({
        message:statusText,
        type:"fail",
        duration:2000
      })
    }
    return {
      status: 0
    }
  })
}