<template>
  <div class="backIcon" @click="backIndex">
    <i class="iconfont icon-shouye"></i>
  </div>
</template>
<script setup>
  import { useRouter } from "vue-router";
  let router=useRouter();
  //返回首页
  const backIndex=()=>{
    router.replace({
      path:'/index'
    })
  }
</script>

<style lang="scss" scoped>
  .backIcon{
    position: fixed;
    bottom: 0.7rem;
    right: 0.2rem;
    width: 0.5rem;
    height: 0.5rem;
    background: #86b2d5;
    border-radius: 50%;
    box-shadow: 0 0 8px #86b2d5;
    text-align: center;
    line-height: 0.5rem;
    i{
      font-size: 0.36rem;
      color: #2264B0;
    }
  }
</style>