import { createRouter, createWebHashHistory } from 'vue-router'

let routes=[
  {
    path: '/',
    redirect:'/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/pages/loginIndex.vue')
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    component: () => import('@/pages/evaluatePage.vue')
  },
  {
    path: '/waitResult',
    name: 'waitResult',
    component: () => import('@/pages/waitResult.vue')
  },
  {
    path: '/planList',
    name: 'planList',
    component: () => import('@/pages/planList.vue')
  },
  {
    path: '/planDetail',
    name: 'planDetail',
    component: () => import('@/pages/planDetail.vue')
  },
  {
    path: '/preBook',
    name: 'preBook',
    component: () => import('@/pages/pre-book.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/pages/privacyPage.vue')
  },
  {
    path: '/verifyPage',
    name: 'verifyPage',
    component: () => import('@/pages/verifyPage.vue')
  },
  {
    path: '/selectProduct',
    name: 'selectProduct',
    component: () => import('@/pages/selectProduct.vue')
  },
  {
    path: '/saveImg',
    name: 'saveImg',
    component: () => import('@/pages/saveImg.vue')
  }
];
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router