import request from "./request";

//短信验证码
export function getSmsCode(data = {}) {
	return request({ 
    url: 'app/resource/sms/code',
    method: 'get',
    headType: "json",
    Authorization:true,
    data,
  })
}
//短信登录
export function smsLogin(data = {}) {
  return request({
    url: 'app/smsLogin',
    method: 'post',
    headType: "json",
    Authorization: true,
    data,
  })
}
//编辑用户信息
export function editUser(data = {}) {
  return request({
    url: 'app/editUser',
    method: 'post',
    headType: "json",
    Authorization: true,
    load:true,
    data,
  })
}
//获取品种
export function getBreedList(data = {}) {
  return request({
    url: 'app/pet/getBreedList',
    method: 'post',
    headType: "json",
    Authorization: true,
    data,
  })
}
//获取疾病
export function getDiseaseList(data = {}) {
  return request({
    url: 'app/pet/getDiseaseList',
    method: 'post',
    headType: "json",
    Authorization: true,
    data,
  })
}
//创建营养测评
export function addPetInfo(data = {}) {
  return request({
    url: 'app/pet/addPetInfo',
    method: 'post',
    headType: "json",
    Authorization: true,
    load:true,
    data,
  })
}
//获取时间段
export function getUserFollowList(data = {}) {
  return request({
    url: 'app/user/getUserFollowList',
    method: 'post',
    headType: "json",
    Authorization: true,
    load:true,
    data,
  })
}
//添加预约
export function addUserFollow(data = {}) {
  return request({
    url: 'app/user/addUserFollow',
    method: 'post',
    headType: "json",
    Authorization: true,
    data,
  })
}
//方案列表
export function getPetPlanList(data = {}) {
  return request({
    url: 'app/pet/getPetPlanList',
    method: 'post',
    headType: "json",
    Authorization: true,
    data,
  })
}
//用户信息
export function getUserInfo(data = {}) {
  return request({
    url: 'app/getUserInfo',
    method: 'post',
    headType: "json",
    Authorization: true,
    data,
  })
}
//计划详情
export function getPetPlanInfo(data = {}) {
  return request({
    url: 'app/pet/getPetPlanInfo',
    method: 'post',
    headType: "json",
    Authorization: true,
    data,
  })
}
//领取方案
export function editPlanInfo(data = {}) {
  return request({
    url: 'app/pet/editPlanInfo',
    method: 'post',
    headType: "json",
    Authorization: true,
    data,
  })
}
//获取食用品牌
export function getQueryList(data = {}) {
  return request({
    url: 'app/pet/getQueryList',
    method: 'get',
    headType: "json",
    Authorization: true,
    data,
  })
}
//获取食用品牌
export function getProductlist(data = {}) {
  return request({
    url: 'app/pet/getProductlist',
    method: 'get',
    headType: "json",
    Authorization: true,
    load:true,
    data,
  })
}
//选择产品
export function editPlanProject(data = {}) {
  return request({
    url: 'app/pet/editPlanProject',
    method: 'post',
    headType: "json",
    Authorization: true,
    load:true,
    data,
  })
}
//获取宠物列表
export function getPetList(data = {}) {
  return request({
    url: 'app/pet/getPetList',
    method: 'post',
    headType: "json",
    Authorization: true,
    data,
  })
}

