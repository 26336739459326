import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Vant from 'vant';
import { createPinia } from 'pinia';
import backIcon from "./components/backIcon.vue";
import "./css/common.scss";
import 'vant/lib/index.css';
import "./font/iconfont.css";
window.__VUE_PROD_DEVTOOLS__ = false;
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;
const app = createApp(App);
const pinia = createPinia();
app.component('backIcon', backIcon);
app.use(router);
app.use(Vant);
app.use(pinia);
router.isReady().then(()=>{
  app.mount('#app')
})

